import "react-app-polyfill/ie11.js"; // For IE 11 support
import "react-app-polyfill/stable.js";
import "core-js";
// import "./polyfill";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import React from "react";
import ReactDOM from "react-dom";
import App from "./App.js";
import * as serviceWorker from "./serviceWorker.js";
import { icons } from "./assets/icons/index.js";
import { Provider } from "react-redux";
import store from "./redux/store/store.js";
import "./index.css";

React.icons = icons;

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
      <ToastContainer autoClose={2000} theme={"colored"} />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
serviceWorker.unregister();

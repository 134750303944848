import React, { Component } from "react";
import { BrowserRouter as Routes, Redirect, Route, Switch } from "react-router-dom";
import "./scss/style.scss";
import { ForgotPassword } from "./views/pages/forgotPassword/ForgotPassword.js";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout.js"));

// Pages
const Login = React.lazy(() => import("./views/pages/login/Login.js"));
const ResetPassword = React.lazy(() =>
  import("./views/pages/forgotPassword/ResetPassword.js")
);
const Register = React.lazy(() => import("./views/pages/register/Register.js"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404.js"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500.js"));

class App extends Component {
  render() {
    return (
      <Routes>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route
              exact
              path="/login"
              name="Login Page"
              render={(props) => <Login {...props} />}
            />
            <Route
              exact
              path="/forgotpassword"
              name="Forgot Password Page"
              render={(props) => <ForgotPassword {...props} />}
            />
            <Route
              exact
              path="/resetpassword/:token"
              name="Reset Password Page"
              render={(props) => <ResetPassword {...props} />}
            />
            <Route
              exact
              path="/register"
              name="Register Page"
              render={(props) => <Register {...props} />}
            />
            <Route
              exact
              path="/404"
              name="Page 404"
              render={(props) => <Page404 {...props} />}
            />
            <Route
              exact
              path="/500"
              name="Page 500"
              render={(props) => <Page500 {...props} />}
            />
            <Route
              path="/"
              name="Home"
              render={(props) =>
                localStorage.getItem("prosalon") ? (
                  <TheLayout {...props} />
                ) : (
                  <Redirect to="/login" />
                )
              }
            />
          </Switch>
        </React.Suspense>
      </Routes>
    );
  }
}

export default App;

import { combineReducers } from "redux";
import adminReducer from "./AdminReducer.js";
import employeeReducer from "./EmployeeReducer.js";
import productReducer from "./productReducer.js";
import serviceReducer from "./serviceReducer.js";
import subServiceReducer from "./subServiceReducer.js";
import appointmentReducer from "./appointmentReducer.js";
import lastMinuteSlotReducer from "./lastMinuteSlotReducer.js";
import logInReducer from "./loginReducer.js";
import offerReducer from "./offerReducer.js";
import voucherReducer from "./voucherReducer.js";
import loyaltySchemeReducer from "./loyaltySchemeReducer.js";
import loyaltyOfferReducer from "./loyaltyOfferReducer.js";
import blogReducer from './BlogReducer.js'
import broadcastNotificationReducer from './broadcastNotificationReducer.js'
import privacyPolicyReducer from './PrivacyPolicyReducer.js'
import termsAndConditionReducer from './TermsAndConditionReducer.js'
import cancellationReducer from './cancellationReducer.js'

const initialState = {
  sidebarShow: "responsive",
};

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case "set":
      return { ...state, ...rest };
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  logInToken: logInReducer,
  sidebarShow: changeState,
  adminData: adminReducer,
  employeeData: employeeReducer,
  productData: productReducer,
  serviceData: serviceReducer,
  subServiceData: subServiceReducer,
  appointmentData: appointmentReducer,
  lastMinuteSlotData: lastMinuteSlotReducer,
  offerData: offerReducer,
  voucherData: voucherReducer,
  loyaltySchemeData: loyaltySchemeReducer,
  getLoyaltyOfferData: loyaltyOfferReducer,
  blogData: blogReducer,
  broadcastNotificationData: broadcastNotificationReducer,
  privacyPolicyData: privacyPolicyReducer,
  termsAndConditionData: termsAndConditionReducer,
  cancellationData: cancellationReducer
});

export default rootReducer;
